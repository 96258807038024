import { useEffect } from "react";
import classnames from "classnames";

// brands
import img_amazon from "./img/amazon.png";
import img_apple from "./img/apple.png";
import img_brex from "./img/brex.png";
import img_cage from "./img/cage.png";
import img_eb from "./img/eb.png";
import img_gather from "./img/gather.png";
import img_google from "./img/google.png";
import img_monitor from "./img/monitor.png";
import img_natgeo from "./img/natgeo.png";
import img_nfl from "./img/nfl.png";
import img_nvite from "./img/nvite.png";
import img_quora from "./img/quora.png";
import img_samsung from "./img/samsung.png";
import img_svmk from "./img/svmk.png";
import img_twitch from "./img/twitch.png";
import img_zillow from "./img/zillow.png";

// work
import img_work0 from "./img/work0.png";
import img_work2 from "./img/work2.png";
import img_work3 from "./img/work3.png";
import img_work4 from "./img/work4.png";
import img_work5 from "./img/work5.png";
import img_work6 from "./img/work6.png";
import img_workcard from "./img/work-card.png";
import img_workwatch from "./img/work-watch.png";
import img_work9 from "./img/work9.png";
import img_workenergy from "./img/work-energy.png";
import img_workmap from "./img/work-map.png";
import img_worknvite from "./img/work-nvite.png";
import img_gatherchat from "./img/gather-chat.png";
import img_card from "./img/card.png";
import img_cage2 from "./img/cage.jpg";
import img_sync from "./img/sync.png";
import img_cmd from "./img/cmd.jpg";
import img_flow from "./img/flow.png";

//  ui
import img_mike from "./img/mike.png";
import img_mikelg from "./img/mikelg.jpg";
import img_rule from "./img/rule.png";
import cursor from "./img/cursor.png";
import img_blockT1 from "./img/block-t-1.png";
import img_blockT2 from "./img/block-t-2.png";
import img_blockT3 from "./img/block-t-3.png";
import img_blockT4 from "./img/block-t-4.png";
import img_blockB1 from "./img/block-b-1.png";
import img_blockB2 from "./img/block-b-2.png";
import img_blockB3 from "./img/block-b-3.png";
import img_blockB4 from "./img/block-b-4.png";

function calcTime() {
  const date = new Date();
  return new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: "short",
    timeZone: "America/Chicago",
  }).format(date);
}

function calcAvailMonth() {
  const date = new Date();
  date.setDate(date.getDate() + 31);
  console.log(date);
  return new Intl.DateTimeFormat("en-US", {
    month: "long",
    timeZone: "America/Chicago",
  }).format(date);
}

function calcAvailYear() {
  const date = new Date();
  date.setDate(date.getDate() + 91);
  return new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    timeZone: "America/Chicago",
  }).format(date);
}

function calcActivity() {
  const date = new Date();

  const weekday = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    timeZone: "America/Chicago",
  }).format(date);

  const time = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    timeZone: "America/Chicago",
  })
    .format(date)
    .split(" ");

  const online = (
    <p className="-mt-1 font-medium text-lime-500 text-sm block text-center md:text-right font-bold flex items-center justify-center md:justify-end">
      Currently at my desk
      <i className="material-icons text-lime-500 ml-2 text-lg">devices</i>
    </p>
  );

  const offline = (
    <p className="-mt-1 text-red-500 text-sm block text-center md:text-right font-bold flex items-center justify-center md:justify-end">
      Away from my desk
      <i className="material-icons text-red-500 ml-2 text-lg">mobile_off</i>
    </p>
  );

  const hour = time[0];
  console.log(hour);
  const timing = time[1];
  const workdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const lazydays = ["Friday"];
  const nomeetdays = ["Wednesday"];

  const isAfternoon = time.includes("PM");
  const isWorkDay = workdays.includes(weekday);
  const isLazyDay = lazydays.includes(weekday);
  const isNoMeetDay = nomeetdays.includes(weekday);
  const isWorkingHours =
    (hour >= 9 && timing === "AM") ||
    ((hour < 5 || hour >= 12) && timing === "PM");

  let act = online;
  if (!isWorkDay) {
    act = offline;
  } else if (isWorkDay && isWorkingHours) {
    act = online;
  } else if (isWorkDay && !isWorkingHours) {
    act = offline;
  }

  return act;
}

function Desk() {
  return (
    <div className="text-center md:text-right">
      <p className="flex items-center justify-center md:justify-end whitespace-nowrap leading-tight">
        <span className="font-bold text-white text-base md:text-base">
          Prosper, TX
        </span>
        <i className="material-icons block text-white ml-2 text-lg md:text-xl leading-tight">
          public
        </i>{" "}
      </p>

      {calcActivity()}
    </div>
  );
}

function HUD() {
  return (
    <div className="flex justify-between pb-8 pt-4 px-4 md:p-8 items-center">
      <img
        src="https://pbs.twimg.com/profile_images/1562874757124685824/0OOB_gcP_400x400.jpg"
        className="rounded-xl w-20 md:w-20 mr-2 shadow"
        alt="Mike Dick's avatar"
        style={{ width: "72px" }}
      />
      <div className="bg-dark md:rounded-xl py-3 pr-8 pl-10 w-full md:w-auto shadow-lg border border-main">
        {Desk()}
      </div>
    </div>
  );
}

function Rule() {
  return (
    <div
      className="rule mx-auto block py-16 md:py-16"
      style={{
        maxWidth: "1000px",
        height: "2px",
      }}
    ></div>
  );
}

function Badge({ color = "midnight", children }) {
  const classes = classnames({
    "text-sm md:text-base mt-3 md:mt-8 md:px-5 px-2 py-1 md:py-2 inline-flex items-center justify-center font-bold rounded-lg": true,
    "bg-dark2 text-gray-400": color === "midnight",
    "hover:bg-lime-500 text-lime-500 bg-midnight-900": color === "green",
  });
  return <div className={classes}>{children}</div>;
}

function Section({ children }) {
  return (
    <div className="mx-auto px-12 md:px-24" style={{ maxWidth: "1200px" }}>
      {children}
    </div>
  );
}

function Title({ size, children }) {
  const classes = classnames({
    "text-white font-bold mb-4 leading-tight md:leading-tight": true,
    "text-2xl md:text-3xl": size === "sm",
    "text-3xl md:text-5xl": size !== "sm",
  });
  return <h3 className={classes}>{children}</h3>;
}

function Para({ children }) {
  return (
    <p className="text-gray-400 text-base md:text-lg font-normal">{children}</p>
  );
}

function Block({ row = 1, w = "4x", color, pattern = "none", children }) {
  const classes = classnames({
    "flex-grow mt-2 pl-2 items-stretch self-stretch": true,
    "w-full md:w-1/4": w == "4x",
    "w-full md:w-1/3": w == "3x",
  });

  const classesinner = classnames({
    "h-full flex justify-center items-center md:transform hover:scale-95 duration-100 text-sm transition-all rounded-xl shadow": true,
    "bg-dark border border-main": row == 1 && color !== "none",
    "bg-dark2 border border-main2": row == 2 && color !== "none",
    "bg-dark3 border border-main3": row == 3 && color !== "none",
    "bg-top":
      pattern === img_blockT1 ||
      pattern === img_blockT2 ||
      pattern === img_blockT3 ||
      pattern === img_blockT4,
    "bg-bottom":
      pattern === img_blockB1 ||
      pattern === img_blockB2 ||
      pattern === img_blockB3 ||
      pattern === img_blockB4,
  });

  return (
    <div className={classes}>
      <div className={classesinner}>{children}</div>
    </div>
  );
}

function CTA() {
  return (
    <div>
      <p className="md:flex items-center">
        <i className="block md:inline material-icons text-lime-500 mr-3 text-3xl pt-2 mb-2 md:mb-0">
          event_available
        </i>{" "}
        <span className="text-xl text-lime-100">
          Book a freelance project ->{" "}
          <a
            className="txtlink underline text-lime-500"
            href="mailto:mike@m1k3.net?subject=👋 Freelance opportunity&body=Hey Mike, I'd love to chat about _______________"
          >
            <span>email me</span>
          </a>
        </span>
      </p>

      <p className="md:flex items-center mt-8 md:mt-2">
        <i className="block md:inline material-icons text-lime-500 mr-3 text-3xl pt-2 mb-2 md:mb-0">
          sticky_note_2
        </i>{" "}
        <span className="text-xl text-lime-100">
          Book UI/UX teardown ->{" "}
          <span className="block md:inline">
            <a
              className="txtlink underline text-lime-500"
              href="mailto:mike@m1k3.net?subject=👋 UI/UX audit request&body=Hey Mike, I'd love to have you audit ______tell_me_about_it_______"
            >
              <span>schedule now</span>
            </a>
          </span>
        </span>
      </p>
    </div>
  );
}

function Brand({ img, title, children }) {
  return (
    <div className="flex flex-col justify-center items-center py-6 md:py-8">
      <img src={img} width="60px" className="mb-4 rounded-full" alt={title} />
      {title && (
        <p className="text-white font-bold text-xl md:mb-2 hidden md:block">
          {title}
        </p>
      )}
      <p className="text-white text-center leading-snug px-4 md:px-8">
        {children}
      </p>
    </div>
  );
}

function Service({ icon = "chat", children }) {
  return (
    <div className="flex justify-center items-center p-8">
      <i className="material-icons text-white text-3xl">{icon}</i>
      <p className="text-white font-bold ml-3 text-lg">{children}</p>
    </div>
  );
}

function Header() {
  return (
    <div
      className="mx-auto md:pt-32 px-12 md:px-24"
      style={{ maxWidth: "1000px" }}
    >
      <h2 className="text-5xl md:text-8xl font-bold leading-tight md:leading-tight mb-16 text-white text-center md:text-left">
        A product design{" "}
        <span className="last-line-row">
          <span className="last-line-element">studio.</span>
          <i />
        </span>
      </h2>

      <CTA />
    </div>
  );
}

function Footer() {
  return (
    <div className="mx-auto pb-8" style={{ maxWidth: "1000px" }}>
      <div className="text-gray-400 text-base pb-16 text-center">
        <span className="text-lime-100">&copy; 2003</span>
        {""}
        &nbsp;&nbsp;M1K3, LLC (Mike Dick)
      </div>
    </div>
  );
}

function App() {
  useEffect(() => {
    const elements = document.querySelectorAll(".last-line");
    const nameElement = "last-line-element";
    const nameRow = "last-line-row";
    const span = document.createElement("i");

    const wrapAll = (nodes, wrapper, elem) => {
      const parent = nodes[0].parentNode;
      const { previousSibling } = nodes[0];
      for (
        let i = 0;
        nodes.length - i;
        wrapper.firstChild === nodes[0] && (i += 1)
      ) {
        wrapper.appendChild(nodes[i]);
        wrapper.appendChild(span);
      }
      if (previousSibling) {
        parent.insertBefore(wrapper, previousSibling.nextSibling);
      } else {
        elem.appendChild(wrapper);
      }
      return wrapper;
    };

    const findLastRow = (elem) => {
      const content = elem.innerText.trim();
      const contentArr = content.split(" ");
      let contentFormatted = "";
      contentArr.forEach((item) => {
        contentFormatted += `<span>${item} </span>`;
      });
      const element = elem;
      element.innerHTML = contentFormatted;

      const childrenSpan = Array.from(elem.getElementsByTagName("span"));
      let top = 0;
      childrenSpan.forEach((item) => {
        const thisTop = item.offsetTop;
        if (thisTop === top) {
          item.classList.add(nameElement);
        } else {
          childrenSpan.forEach((el) => {
            el.classList.remove(nameElement);
          });
          top = thisTop;
          item.classList.add(nameElement);
        }
      });

      const wrapElements = element.querySelectorAll(`.${nameElement}`);
      const wrapper = document.createElement("span");
      wrapper.classList.add(nameRow);
      wrapAll(wrapElements, wrapper, elem);
    };

    const findLastRows = () => {
      elements.forEach((elem) => {
        findLastRow(elem);
      });
    };

    window.addEventListener("resize", () => {
      findLastRows();
    });
    findLastRows();
  }, []);

  return (
    <div
      className="text-white font-sans pt-36 md:pt-48"
      style={{
        backgroundSize: "50%",
        backgroundRepeat: "repeat-x",
        cursor: `url(${cursor}), default`,
      }}
    >
      <div className="fixed z-50 left-0 top-0 right-0">
        <HUD />
      </div>
      <Section>
        <Title>
          I'm Mike, a designer{" "}
          <span className="font-mono text-lime-500">&</span> coder that builds
          my ideas and helps you with yours.
        </Title>
        <Para>
          I run a product studio that has an established, repeatable method for
          designing and building successful products. It's also an operating
          model with two sides: your products and my products. Both complement
          each other with shared learnings that feed into a continuous
          improvement cycle for the both of us.
        </Para>
        <Para>
          <div className="mt-8 text-lime-500 uppercase text-sm font-bold">
            My startups
          </div>
        </Para>

        <div className="flex flex-wrap mt-2 -ml-2 pb-24 md:28">
          <Block w="4x" row="1" pattern={img_blockT3}>
            <Brand img="https://www.usegather.com/new-logo.png" title="Gather">
              <span className="text-gray-400 block">
                The collaborative personal finance tool for the modern couple.
              </span>
              <a href="https://usegather.com" target="_blank">
                <Badge color="green">
                  <i className="material-icons text-lg mr-2">launch</i> Launch
                </Badge>
              </a>
            </Brand>
          </Block>
          <Block w="4x" row="1" pattern={img_blockT1}>
            <Brand img={img_cage} title="Cage">
              <span className="text-gray-400 block">
                Media collaboration and project management for creative teams.
              </span>
              <Badge>
                <i className="material-icons text-lg mr-2">check_circle</i>{" "}
                Acquired
              </Badge>
            </Brand>
          </Block>
          <Block w="4x" row="1" pattern={img_blockT3}>
            <Brand img={img_nvite} title="nvite">
              <span className="text-gray-400 block">
                The most seamless and social events platform for RSVP’ing and
                ticketing.
              </span>
              <Badge>
                <i className="material-icons text-lg mr-2">check_circle</i>{" "}
                Acquired
              </Badge>
            </Brand>
          </Block>
          <Block w="4x" row="1" pattern={img_blockT2}>
            <Brand img={img_monitor} title="Monitor">
              <span className="text-gray-400 block">
                Get a text message when your website or app goes offline.
                Quietly retired.
              </span>
              <Badge>
                <i className="material-icons text-lg mr-2">check_circle</i>{" "}
                Exited
              </Badge>
            </Brand>
          </Block>
        </div>
      </Section>
      <Section>
        <Title size="sm">My craft adapts to your team.</Title>
        <Para>
          I bring a wide range of experience spanning the past two decades
          founding startups and partnering with clients. In doing so, I built a
          skillset that adapts to fill the role your team needs. My bread and
          butter is design systems and how they empower collaboration and/or
          unify fragmentation, but I'd love to hear about your project and how I
          can help.
        </Para>

        <Para>
          <div className="mt-8 text-lime-500 uppercase text-sm font-bold">
            My skills
          </div>
        </Para>

        <div className="flex flex-wrap mt-2 pb-12 md:pb-32 -ml-2">
          <Block w="3x" row="1" pattern={img_blockT4}>
            <Service icon="sports_esports">Product Strategy</Service>
          </Block>
          <Block w="3x" row="1" pattern={img_blockT3}>
            <Service icon="create">Product Design</Service>
          </Block>
          <Block w="3x" row="1" pattern={img_blockT1}>
            <Service icon="code">Front-end Development</Service>
          </Block>
          <Block w="3x" row="2">
            <Service icon="extension">Design Systems</Service>
          </Block>
          <Block w="3x" row="2">
            <Service icon="toggle_on">Interface Design</Service>
          </Block>
          <Block w="3x" row="2">
            <Service icon="auto_fix_high">Product Marketing</Service>
          </Block>
          <Block w="3x" row="3" pattern={img_blockB2}>
            <Service icon="smart_button">UI Components</Service>
          </Block>
          <Block w="3x" row="3" pattern={img_blockB3}>
            <Service icon="chat_bubble">User Research</Service>
          </Block>
          <Block w="3x" row="3" pattern={img_blockB1}>
            <Service icon="color_lens">Visual Design</Service>
          </Block>
        </div>
      </Section>
      <Section>
        <Title size="sm">
          I've worked with{" "}
          <span className="font-mono text-lime-500">&lt;3</span>'ed brands.
        </Title>
        <Para>
          Based in Texas, partnering remotely with companies big and small –
          often joining internal Slacks and meetings so that I'm around to
          understand your business. I've worked in every environment imaginable:
          agency-life, self-employed, and in-house where I grassrooted design
          systems from scratch to help designers and engineers collaborate
          better, together.
        </Para>

        <Para>
          <div className="mt-8 text-lime-500 uppercase text-sm font-bold">
            brands
          </div>
        </Para>

        <div className="block md:flex md:flex-wrap mt-2 -ml-2 pb-12 md:pb-12">
          <Block row="1" pattern={img_blockT1}>
            <Brand img={img_google}>
              Prototyped aspirational interactive patterns
            </Brand>
          </Block>
          <Block row="1" pattern={img_blockT2}>
            <Brand img={img_svmk}>
              Design system that unified all products
            </Brand>
          </Block>
          <Block row="1" pattern={img_blockT3}>
            <Brand img={img_nfl}>Design/coded a social media aggregator</Brand>
          </Block>
          <Block row="1" pattern={img_blockT4}>
            <Brand img={img_natgeo}>Design/coded Cat Week explorer hub</Brand>
          </Block>
          <Block row="2">
            <Brand img={img_quora}>
              Design systems, UI components, mentorship
            </Brand>
          </Block>
          <Block row="2">
            <Brand img={img_brex}>Helped launch their first SaaS product</Brand>
          </Block>
          <Block row="2">
            <Brand img={img_amazon}>Design systems, UI components</Brand>
          </Block>
          <Block row="2">
            <Brand img={img_apple}>Design/prototyped concept demos</Brand>
          </Block>
          <Block row="3" pattern={img_blockB1}>
            <Brand img={img_zillow}>
              Product design,
              <br />
              UI design
            </Brand>
          </Block>
          <Block row="3" pattern={img_blockB2}>
            <Brand img={img_eb}>Design/coded SXSW interactive collection</Brand>
          </Block>
          <Block row="3" pattern={img_blockB3}>
            <Brand img={img_samsung}>Design/coded samsung.com site</Brand>
          </Block>
          <Block row="3" pattern={img_blockB4}>
            <Brand img={img_twitch}>Design systems, UI components</Brand>
          </Block>
        </div>
        <Para>
          <div className="mt-1 text-lime-500 uppercase text-sm font-bold">
            Work
          </div>
        </Para>
        <div className="flex flex-wrap mt-2 -ml-2 pb-24 md:pb-28">
          <Block color="none" w="3x">
            <img src={img_work0} alt="Gather project" className="rounded" />
          </Block>
          <Block color="none" w="3x">
            <img
              src={img_work2}
              alt="SurveyMonkey Design System project"
              className="rounded"
            />
          </Block>
          <Block color="none" w="3x">
            <img
              src={img_work3}
              alt="SurveyMonkey Components project"
              className="rounded"
            />
          </Block>
          <Block color="none" w="3x">
            <img
              src={img_work6}
              alt="Music player project"
              className="rounded"
            />
          </Block>
          <Block color="none" w="3x">
            <img src={img_workcard} alt="Nvite project" className="rounded" />
          </Block>
          <Block color="none" w="3x">
            <img src={img_card} alt="Map project" className="rounded" />
          </Block>
          <Block color="none" w="3x">
            <img src={img_work4} alt="Quora project" className="rounded" />
          </Block>
          <Block color="none" w="3x">
            <img
              src={img_work9}
              alt="Gather budgets project"
              className="rounded"
            />
          </Block>
          <Block color="none" w="3x">
            <img
              src={img_workenergy}
              alt="Energy project"
              className="rounded"
            />
          </Block>
          <Block color="none" w="3x">
            <img src={img_workmap} alt="Map project" className="rounded" />
          </Block>
          <Block color="none" w="3x">
            <img src={img_gatherchat} alt="Map project" className="rounded" />
          </Block>
          <Block color="none" w="3x">
            <img src={img_cage2} alt="Map project" className="rounded" />
          </Block>
          <Block color="none" w="3x">
            <img src={img_sync} alt="Map project" className="rounded" />
          </Block>
          <Block color="none" w="3x">
            <img src={img_flow} alt="Map project" className="rounded" />
          </Block>
          <Block color="none" w="3x">
            <img src={img_cmd} alt="Map project" className="rounded" />
          </Block>
        </div>
      </Section>
      <Footer />
    </div>
  );
}

export default App;
